@tailwind base;
@tailwind components;
@tailwind utilities;
@import 'codemirror/lib/codemirror.css';
@import 'codemirror/theme/material.css';
@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');
@import 'prismjs/themes/prism.css';
@import 'react-toastify/dist/ReactToastify.css';
@import '@nosferatu500/react-sortable-tree/style.css';

@keyframes Narrow {
    from {
        width: 22rem;
    }

    to {
        width: 1.5rem;
    }
}

@keyframes Widen {
    from {
        width: 1.5rem;
    }

    to {
        width: 22rem;
    }
}


@keyframes MoveRightToLeft {
    from {
        margin-left: 22rem;
    }

    to {
        margin-left: 2rem;
    }
}

@keyframes MoveLeftToRight {
    from {
        margin-left: 2rem;
    }

    to {
        margin-left: 22rem;
    }

    @keyframes MoveRightToLeft {
        0% {
            margin-left: 22rem;
        }

        100% {
            margin-left: 3.5rem;
        }
    }

    @keyframes MoveLeftToRight {
        0% {
            margin-left: 3.5rem;
        }

        100% {
            margin-left: 22rem;
        }
    }

}

@keyframes NarrowLg {
    from {
        width: 30rem;
    }

    to {
        width: 1.5rem;
    }
}

@keyframes WidenLg {
    from {
        width: 1.5rem;
    }

    to {
        width: 30rem;
    }
}


@keyframes MoveRightToLeftLg {
    from {
        margin-left: 30rem;
    }

    to {
        margin-left: 2rem;
    }
}

@keyframes MoveLeftToRightLg {
    from {
        margin-left: 2rem;
    }

    to {
        margin-left: 30rem;
    }
}

@media screen and (min-width: 786px) {
    @keyframes Narrow {
        from {
            width: 22rem;
        }

        to {
            width: 3rem;
        }
    }

    @keyframes Widen {
        from {
            width: 3rem;
        }

        to {
            width: 22rem;
        }
    }

    @keyframes MoveRightToLeft {
        from {
            margin-left: 22rem;
        }

        to {
            margin-left: 3.5rem;
        }
    }

    @keyframes MoveLeftToRight {
        from {
            margin-left: 3.5rem;
        }

        to {
            margin-left: 22rem;
        }
    }

    @keyframes NarrowLg {
        from {
            width: 30rem;
        }

        to {
            width: 3rem;
        }
    }

    @keyframes WidenLg {
        from {
            width: 3rem;
        }

        to {
            width: 30rem;
        }
    }

    @keyframes MoveRightToLeftLg {
        from {
            margin-left: 30rem;
        }

        to {
            margin-left: 3.5rem;
        }
    }

    @keyframes MoveLeftToRightLg {
        from {
            margin-left: 3.5rem;
        }

        to {
            margin-left: 30rem;
        }
    }

}

@media screen and (min-width: 1200px) {
    @keyframes ContentLeftToRight {
        from {
            padding-left: 3.5rem;
        }

        to {
            padding-left: 22rem;
        }
    }

    @keyframes ContentRightToLeft {
        from {
            padding-left: 22rem;
        }

        to {
            padding-left: 3.5rem;
        }
    }

    @keyframes ContentLeftToRightLg {
        from {
            padding-left: 3.5rem;
        }

        to {
            padding-left: 30rem;
        }
    }

    @keyframes ContentRightToLeftLg {
        from {
            padding-left: 30rem;
        }

        to {
            padding-left: 3.5rem;
        }
    }
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #353a44 !important;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

*,
*::before,
*::after {
    box-sizing: border-box;
}

* {
    margin: 0;
}

:root {
    --default-vertical-spacing: 0.75rem;
    --nav-height: 71.594px;
    --footer-height: 61px;
    --landing-page-max-width: 1200px;

    --sans: -apple-system, BlinkMacSystemFont, Inter, Segoe UI, Helvetica Neue,
        sans-serif;
    --serif: Tiempos, serif;
    --mono: GT America Mono, Consolas, Monaco, 'Andale Mono', 'Ubuntu Mono',
        monospace;
    --font-size-0: 8px;
    --font-size-1: 12px;
    --font-size-2: 13px;
    --font-size-3: 16px;
    --font-size-4: 18px;
    --font-size-5: 24px;
    --font-size-6: 32px;
    --font-size-7: 38px;
    --font-size-jumbo: 79px;

    --line-height-1: 16px;
    --line-height-2: 20px;
    --line-height-3: 25px;
    --line-height-4: 28px;
    --line-height-5: 32px;
    --line-height-6: 40px;
    --line-height-7: 48px;
    --line-height-jumbo: 90px;
}

:root,
.light {
    --white: #ffffff;
    --black: #18181b;
    --black-light: #58585f;
    --black-medium: #2c2c30;
    --gray-light: #f6f9fc;
    --gray-medium: #dce6e9;

    --yellow: #ffd848;
    --purple: #6e00f5;
    --green: #0a993e;
    --blue: #0073e6;
    --magenta: #dc34dc;

    --theme: var(--yellow);
    --stripe: #0a2540;

    --light: var(--white);
    --dark: var(--black);
    --text: var(--dark);

    --contrast-dark: var(--dark);
    --code-background: var(--gray-light);
    --code-border: var(--gray-medium);
    --toc-border: rgba(0, 0, 0, 0.14);
    --translucent: rgba(0, 0, 0, 0.4);

    --docsearch-primary-color: var(--black-medium) !important;
    --docsearch-logo-color: var(--black-medium) !important;
}

.dark {
    --stripe: var(--white);

    --light: var(--black);
    --dark: var(--white);
    --text: var(--dark);

    --contrast-dark: var(--black-medium);
    --code-background: var(--black-medium);
    --code-border: var(--black-light);
    --toc-border: var(--black-light);
    --translucent: rgba(255, 255, 255, 0.4);
}

.dark,
/* Landing page uses a custom pre w/ a black background and --dark mode colors */
.page--landing pre[class*='language-'],
.markdoc-editor .CodeMirror {
    --light: var(--black);
    --dark: var(--white);
    --text: var(--dark);

    --purple: var(--yellow);
    /* Change purple to yellow in dark mode */
    --green: #00d924;
    --blue: #80e9ff;
    --magenta: #ff80ff;
}


html,
body,
#__next {
    height: 100%;
    padding: 0;
    margin: 0;
    font-family: var(--sans);
}

body {
    color: var(--text);
    background: var(--light);
    font-family: var(--sans);
}

body ::selection,
body.dark ::selection {
    color: var(--black) !important;
    background: var(--theme) !important;
}

body.modal-is-active {
    /* Override the body scrolling behavior when there's a modal open in order to avoid dual scroll bars */
    overflow: hidden;
}

a {
    text-decoration: underline;
    text-decoration-thickness: 1px;
    text-underline-offset: 1px;
    transition: opacity 300ms ease;
}

a:hover {
    opacity: 0.75;
}

h1,
.h1 {
    font-size: var(--font-size-7);
    line-height: var(--line-height-7);
    margin-bottom: 0.5rem;
    @apply font-bold;
}

h2,
.h2 {
    font-size: var(--font-size-5);
    line-height: var(--line-height-5);
    margin-top: 2.5rem;
    margin-bottom: 0.5rem;
    @apply font-bold;
}

h3,
.h3 {
    font-size: var(--font-size-4);
    line-height: var(--line-height-4);
    margin-top: 2rem;
    margin-bottom: 0.5rem;
    @apply font-bold;
}

h4,
.h4 {
    font-size: var(--font-size-3);
    line-height: var(--line-height-3);
    margin-top: 1rem;
    margin-bottom: 0.5rem;
    @apply font-bold;
}

h5,
.h5 {
    font-size: var(--font-size-3);
    line-height: var(--line-height-3);
    font-weight: normal;
    margin-bottom: 0.5rem;
    @apply font-bold;
}

code {
    font-size: inherit;
    font-size: 15px;
    font-family: var(--mono);
    font-weight: 500;
    padding: 1px 2px;
    background: var(--code-background);
    border: 1px solid var(--code-border);
    border-radius: 3px;
    word-break: break-all;
}

img {
    max-width: 100%;
}

pre {
    border-radius: 3px;
    border: 1px solid var(--code-border);
}

.box pre {
    border: none;
}

.box pre[class*="language-"] {
    padding: 0.5rem;
}

table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
    margin-top: 2rem;
    margin-bottom: 2rem;
    overflow: hidden;
}

th,
td>strong {
    font-size: var(--font-size-3);
    line-height: var(--line-height-3);
    font-family: var(--sans);
    font-weight: 500;
    text-align: left;
    padding: 0 16px 8px;
}

td {
    padding: 12px 16px 16px;
    background-clip: padding-box;
    border-bottom: 1px solid var(--code-border);
}

tr:first-child td {
    border-top: 1px solid var(--code-border);
}

tr td:first-child {
    border-left: 1px solid var(--code-border);
    border-right: 1px solid var(--code-border);
}

tr td:last-child {
    border-right: 1px solid var(--code-border);
}

tr:first-child td:first-child {
    border-top-left-radius: 3px;
}

tr:last-child td:first-child {
    border-bottom-left-radius: 3px;
}

tr:first-child td:last-child {
    border-top-right-radius: 3px;
}

tr:last-child td:last-child {
    border-bottom-right-radius: 3px;
}

article {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    max-width: 100%;
    min-width: 0;
}


/* Syntax highlighting */
pre[class*='language-'] {
    /* Override Prism styles */
    text-shadow: none !important;
    color: var(--text) !important;
    background: var(--code-background) !important;
    font-size: 14px !important;
    line-height: 22px !important;
    font-family: var(--mono) !important;
    margin: 0 0 var(--default-vertical-spacing) !important;
}

.box-success pre[class*='language-'] {
    background: #F9FAFB !important;
}

.box-error pre[class*='language-'] {
    background: #F9FAFB !important;
}


pre[class*='language-'] .md-link {
    color: inherit !important;
}

.token {
    color: var(--text) !important;
    background: transparent !important;
}

.token.comment {
    color: var(--translucent) !important;
}

pre .token.string,
pre .token.boolean,
pre .token.number {
    color: var(--magenta) !important;
}

pre .token.function,
pre .token.tagType,
pre .token.tag {
    color: var(--blue) !important;
}

pre .token.keyword,
.cm-attribute {
    color: var(--purple) !important;
}

pre .token.variable {
    color: var(--green) !important;
}


main .CodeMirror,
main .react-codemirror2 {
    width: 100%;
    cursor: text;
    height: 100%;
    font-family: var(--mono);
    font-size: 13px;
    line-height: 21px;
}

/* CodeMirror styles to apply to in page editor too */
main .CodeMirror-gutters {
    background-color: var(--contrast-dark) !important;
    border-right: 1px solid rgba(255, 255, 255, 0.32) !important;
    padding-right: 8px
}

main .CodeMirror-selected,
main .CodeMirror-selectedtext {
    color: var(--black) !important;
    background: var(--theme) !important;
}

main .CodeMirror-linenumber {
    color: rgba(255, 255, 255, 0.32);
}

main .CodeMirror-cursor {
    border-color: var(--theme);
}

main .CodeMirror-scroll {
    overflow-x: hidden;
    -ms-overflow-style: none;
    /* for Internet Explorer, Edge */
    scrollbar-width: none;
    /* for Firefox */
}

main .CodeMirror-scroll::-webkit-scrollbar {
    display: none;
    /* for Chrome, Safari, and Opera */
}


.markdoc-editor .CodeMirror {
    height: 85vh;
}

.docs {
    scroll-margin: 3rem;
}

.mt-POST {
    color: #F0800F;
}

.mt-GET {
    color: #4bca2e;
}

.mt-DELETE {
    color: #b13600;
}

.mt-PUT {
    color: #045ad0;
}

.mt-PATCH {
    color: #045ad0;
}

.bg-linear-purple {
    background: linear-gradient(90deg, rgb(200, 70, 118) 0%, rgb(150, 94, 184) 100%);
}

ul li {
    @apply mb-1;
}

.documentation a {
    @apply no-underline transition-opacity;
}

.btn-upload {
    background: linear-gradient(90deg, rgb(185 88 124) 0%, rgb(195 134 233) 100%)
}

.documentation ul {
    display: block;
    list-style-type: disc;
    margin-block-start: 0.5em;
    margin-block-end: 0.5em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 2rem;
}

.documentation li {
    display: list-item;
    text-align: -webkit-match-parent;
}

.type-integer,
.type-float {
    @apply text-green-500;
}

.type-string {
    @apply text-orange-500;
}

.type-boolean {
    @apply text-blue-500;
}

.type-array {
    @apply text-purple-500;
}

.type-object {
    @apply text-amber-500;
}

.type-date {
    @apply text-rose-500;
}

.rq-required {
    @apply text-red-500;
}

.rq-nullable {
    @apply text-gray-500;
}

blockquote {
    background: #f9f9f9;
    border-left: 10px solid #ccc;
    padding: 0.5em 10px;
    quotes: "\201C" "\201D" "\2018" "\2019";
}

blockquote:before {
    color: #ccc;
    content: open-quote;
    font-size: 4em;
    line-height: 0.1em;
    margin-right: 0.35em;
    vertical-align: -0.4em;
}

blockquote p {
    display: inline;
}

.box-success .title {
    @apply text-green-500 bg-green-50 border-b;
}

.box-success .content {
    @apply bg-gray-50;
}

.box-error .content {
    @apply bg-gray-50;
}

.box-success {
    @apply border;
}

.box-error {
    @apply border;
}

.box-error .title {
    @apply text-red-500 bg-[#faf1f1] border-b;
}

.box .param {
    @apply px-2;
}

.param {
    @apply border-b;
}

.param .param {
    @apply border-b-0;
}

@layer components {
    .sidebar .active {
        @apply bg-gray-200 font-semibold;
    }

    .box .route {
        @apply p-1 bg-gray-50 mb-0;
    }

    p {
        @apply my-2;
    }

}

/* react-sortable-tree */
.rst__moveHandle,
.rst__loadingHandle {
    width: 2rem;
}

.rst__node {
    height: 3rem !important;
}

.rst__rowWrapper {
    padding: 0.3rem 0.3rem 0.3rem 0;
}

.rst__rowLabel {
    @apply truncate w-full p-0
}

.rst__rowTitle {
    @apply font-medium
}

.rst__rowContents {
    width: 18rem;
    @apply p-0
}

.rst__collapseButton {
    background: #fff url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxOCIgaGVpZ2h0PSIxOCI+PGNpcmNsZSBjeD0iOSIgY3k9IjkiIHI9IjgiIGZpbGw9IiNGRkYiLz48ZyBzdHJva2U9IiM5ODk4OTgiIHN0cm9rZS13aWR0aD0iMS45IiA+PHBhdGggZD0iTTQuNSA5aDkiLz48L2c+Cjwvc3ZnPg==') no-repeat center;
}

.rst__expandButton {
    background: #fff url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxOCIgaGVpZ2h0PSIxOCI+PGNpcmNsZSBjeD0iOSIgY3k9IjkiIHI9IjgiIGZpbGw9IiNGRkYiLz48ZyBzdHJva2U9IiM5ODk4OTgiIHN0cm9rZS13aWR0aD0iMS45IiA+PHBhdGggZD0iTTQuNSA5aDkiLz48cGF0aCBkPSJNOSA0LjV2OSIvPjwvZz4KPC9zdmc+') no-repeat center;
}